import Vue from "vue"
import axios from "axios"
import VueAxios from "vue-axios"
import JwtService from "@/core/services/jwt.service"
import store from '@/core/services/store'
import router from '@/router.js'
import { LOGOUT } from "@/core/services/store/auth.module"

/**
 * Service to call HTTP request via Axios
 */

const base_url = process.env.VUE_APP_API_ENDPOINT

let tenantconfig = JSON.parse(localStorage.getItem('tenantconfig'))
if (tenantconfig == null) {
  tenantconfig = {}
}

window.base_url = base_url
window.configuration = JSON.parse(window.localStorage.getItem('configuration'))

const ApiService = {
  bridge: null,

  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = base_url

    this.bridge = axios.create({
      // baseURL: "http://localhost:8002/api/",
      baseURL: process.env.VUE_APP_API_SATUSEHAT_ENDPOINT,
      headers: {
        'Authorization': `Bearer ${JwtService.getTokenBridge()}`
      }
    });
    this.setHeader()
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`

    if (tenantconfig.hasOwnProperty("database_ip") || tenantconfig.hasOwnProperty("database_name")) {
      Vue.axios.defaults.headers.common[
        "hostip"
      ] = tenantconfig.database_ip

      Vue.axios.defaults.headers.common[
        "name"
      ] = tenantconfig.database_name
    }
  },

  setHeaderBridge(bearerToken = null) {
    let accessToken = bearerToken
    if(accessToken == null){
      accessToken = JwtService.getTokenBridge();
    }
    this.bridge.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    this.bridge.defaults.headers.Authorization = `Bearer ${accessToken}`;

    this.bridge.defaults.headers.common[
      "accesstoken"
    ] = JwtService.getTokenSatuSehat()
  },

  setDbConfigHeader(dbConfig) {
    Vue.axios.defaults.headers.common[
      "hostip"
    ] = dbConfig.database_ip

    Vue.axios.defaults.headers.common[
      "name"
    ] = dbConfig.database_name
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      if (error.response.status == 401) {
        store.dispatch(LOGOUT)
          .then(() => router.push({ name: "login" }));
      }
      return error.response
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}${slug}`).catch(error => {
      if (error.response.status == 401) {
        store.dispatch(LOGOUT)
          .then(() => router.push({ name: "login" }));
      }
      return error.response
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params)
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  // put(resource, params) {
  //   return Vue.axios.put(`${resource}`, params)
  // },
  put(resource, params) {
    resource += "?_method=PUT"
    return Vue.axios.post(`${resource}`, params)
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    if (resource.includes("?")) {
      resource += "&_method=DELETE"
    } else {
      resource += "?_method=DELETE"
    }
    return Vue.axios.post(resource).catch(error => {
      if (error.response.status == 401) {
        store.dispatch(LOGOUT)
          .then(() => router.push({ name: "login" }));
      }
      return error.response
    })
  }

}

export default ApiService
