import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "@/core/services/store"
import ApiService from "@/core/services/api.service"
// import MockService from "@/core/mock/mock.service"
// import { VERIFY_AUTH } from "@/core/services/store/auth.module"
// import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module"

// print
import VueHtmlToPaper from 'vue-html-to-paper';

// print excel
import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueExcelXlsx);

// draw
import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

const options = {
  name: 'cetak',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ]
}

Vue.use(VueHtmlToPaper, options);

// text editor
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor);

import tinymce from 'vue-tinymce-editor'
Vue.component('tinymce', tinymce)

Vue.config.productionTip = false

// Global 3rd party plugins
import "popper.js"
import "tooltip.js"
import PerfectScrollbar from "perfect-scrollbar"
window.PerfectScrollbar = PerfectScrollbar
import ClipboardJS from "clipboard"
window.ClipboardJS = ClipboardJS

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n"
import vuetify from "@/core/plugins/vuetify"
import Swal from 'sweetalert2'
import "@/core/plugins/portal-vue"
import "@/core/plugins/bootstrap-vue"
import "@/core/plugins/perfect-scrollbar"
import "@/core/plugins/highlight-js"
import "@/core/plugins/inline-svg"
import "@/core/plugins/apexcharts"
import "@/core/plugins/treeselect"
import "@/core/plugins/metronic"
import "@mdi/font/css/materialdesignicons.css"
import "@/core/plugins/formvalidation"
import "moment"

import '@/assets/css/main.css'

// ckeditor

// API service init
ApiService.init()

// Remove this to disable mock API
// MockService.init()

// SweetAlert
window.Swal = Swal
window.SwalLoading = Swal.mixin({
  title: 'Loading',
  text: 'Sedang memproses data',
  icon: 'info',
  allowOutsideClick: false,
  allowEscapeKey: false,
  showConfirmButton: false,
  heightAuto: false,
  willOpen: () => {
    Swal.showLoading()
  }
})

window.s3Endpoint = `https://is3.cloudhost.id/medhop-prod-bucket`

router.beforeEach((to, from, next) => {

  document.title = to.meta.title
  next()

  let strg = JSON.parse(localStorage.getItem('tenantconfig'))
  let userD = JSON.parse(localStorage.getItem('user'))
  let subs_end = strg.end_date
  let current_date = new Date().toISOString().split('T')[0]

  if (userD != null) {
    if (subs_end < current_date) {
      let url = window.location.href
      url = url.split('/')

      // if (from.path != "/" && to.path != "/login" && url[url.length - 1] != "package" && url[url.length - 1] != "checkout" && url[url.length - 1] != "payment" && url[url.length - 2]+"/"+url[url.length - 1] != "billings/list") {
      if (to.path != "/login" && to.path != "/subscribe/package" && to.path != "/subscribe/checkout" && to.path != "/subscribe/payment" && to.path != "/billings/list" && to.path != "/subscribe-confirmation") {
        Swal.fire({
          icon: 'warning',
          title: 'Perhatian',
          text: 'Masa aktif anda telah habis, silahkan lakukan perpanjangan',
          timerProgressBar: true,
          timer: 5000,
          showConfirmButton: false,

        }).then(() => {
          router.push({
            path: '/subscribe/package',

          })
            .catch(() => true);
        })
      }
    }
  }


  // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  // store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app")
