import ApiService from "../api.service";
import JwtService from "@/core/services/jwt.service";
import store from '@/core/services/store'
import { UPDATE_PERSONAL_INFO } from '@/core/services/store/profile.module'
import qs from "querystring";

import Swal from 'sweetalert2'

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_USER = "setUserData";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    var login_data = qs.stringify({
      username: credentials.email,
      password: credentials.password,
      grant_type: "password",
      client_id: JwtService.getConfiguration().client_id,
      // Host
      // client_secret: "ppnBdSw8Wt1LBMlv7YiBDPm8xh49ZlfO7SFEjlzR",
      // Local
      // client_secret: "u0yxajo0tHfaAIlHSSB2Oxay8jD8TohCqsqJLzG6",
      // Multitenant
      client_secret: JwtService.getConfiguration().client_secret,
      scope: "*"
    });
    return new Promise((resolve) => {
      ApiService.post("oauth/token", login_data)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          ApiService.setHeader();
          context.commit(SET_USER);
          resolve(data);

        })
        .catch(() => {
          context.commit(SET_ERROR);
          Swal.fire({
            title: '',
            text: 'Email atau password tidak valid',
            icon: 'error',
          })
        });
    });
  },
  [LOGOUT](context) {
    let currentUser = JSON.parse(window.localStorage.getItem("user"))
    // console.log('currentUser auth moduke', currentUser)
    if(currentUser !== null){
      ApiService.post(`users/${currentUser.id}/revoke`)
        .then(( response ) => {
          // console.log(response)
          context.commit(PURGE_AUTH);
        })
        .catch((error) => {
          // console.log('ressponseerror', error)
        });
    }
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      // console.log("creden", credentials)
      ApiService.post("api/users", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.data.data);
          // console.log("setelah di tambah ke api", data)
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
   //  console.log('before verify');
    if (JwtService.getToken()) {
     //  console.log('gettoken true');
      ApiService.setHeader();
      ApiService.get("api/verify")
        .then(({ data }) => {
        //   console.log("verify", data)
          // context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
         //  console.log("error", response)
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
     //  console.log('get token false');
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
   //  console.log(state)
   //  console.log(error)
  },
  [SET_AUTH](state, user) {

    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    // JwtService.saveToken(state.user.token);
    JwtService.saveToken(state.user.access_token);
  },
  [SET_USER](state) {
    let access_right, position_right
    ApiService.get('users/me').then((data) => {
      ApiService.get('users/' + data.data.data.user_id).then((response) => {
       //  console.log("user access", response)
        if (data.data.data.position_id) {
          ApiService.get("positions/" + data.data.data.position_id).then((result) => {

            access_right = response.data.meta.access_right
            position_right = result.data.meta.access_right

            window.localStorage.setItem("access_right", access_right + ", " + position_right)

            let user = data.data.data
            user.access_right = access_right + ", " + position_right
            JwtService.setUser(JSON.stringify(user))
            store.dispatch(UPDATE_PERSONAL_INFO, user)

          })
        }
        else if (!data.data.data.position_id) {
          window.localStorage.setItem("access_right", response.data.meta.access_right)

          let user = data.data.data
          access_right = response.data.meta.access_right
          user.access_right = response.data.meta.access_right
          JwtService.setUser(JSON.stringify(user))
          store.dispatch(UPDATE_PERSONAL_INFO, user)

        }
      })
    })
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
